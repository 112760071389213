import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { roleIsAtLeast, userRoles } from '@oup/shared-node-browser/user.js';

import getClassLink from '../../structure/HubDashboardLayout/Services/getClassLink';
import ClassTile from '../../components/ClassTile/ClassTile';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import TileContainer from '../../components/TileContainer/TileContainer';
import { GLYPHS } from '../../components/Button/Button';
import processUserRole from '../../structure/HubDashboardLayout/Services/processUserRole';
// HOC
import withBreakpoint from '../../decorators/withBreakpoint';
import withLocalizedContent from '../../language/withLocalizedContent';
// Redux
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import {
  openCreateClassModal,
  openEditClassroomModal,
  toggleArchiveClassModal,
  openJoinClassWithCodeModal,
  openOnboardingWizard
} from '../../redux/actions/hubUi';
import { setClassroomsToArchive } from '../../redux/reducers/classroomArchive';
import { HubIllustrationConstants, HubLayoutConstants } from '../../globals/hubConstants.js';
import { isLtiMode } from '../../utils/platform';
import HubPageLayout from '../../structure/HubPageLayout/HubPageLayout';
import getPageLayoutData from '../../structure/HubDashboardLayout/Utils/getPageLayoutData';

function MyClasses({
  classes,
  classesIds,
  openOnboardingWizardAction,
  onClickJoinClassWithCodeHandler,
  hubContent,
  breakpoint,
  areProfileClassesLoading,
  history,
  currentOrganisationId,
  currentOrganisationLti,
  userId,
  userRole,
  setClassToArchiveAction,
  archiveClassModalAction,
  openEditClassroom,
  initialiseSearch,
  organization
}) {
  const processedUserRole = processUserRole(userRole, hubContent);
  const pageLayout = getPageLayoutData({
    pathname: HubLayoutConstants.PATH_NAMES.CLASSES_PATH,
    content: hubContent,
    currentOrganisationLti,
    orgData: {
      orgId: currentOrganisationId,
      orgShortCode: organization.customId,
      orgName: organization.name
    },
    userData: {
      userId,
      userRole
    }
  });
  const {
    CLASSES: {
      emptyStateTitle = '',
      emptyStateSubtitle = '',
      showFirstEmptyStateButton = false,
      firstButtonText = '',
      firstButtonLink = '',
      isFirstButtonPrimary = false,
      showEmptyStateJoinClassWithCodeButton = false,
      joinClassWithCodeButtonText = ''
    } = {}
  } = processedUserRole;

  const _setClassToArchive = classId => {
    if (classId && currentOrganisationId) setClassToArchiveAction(classId, currentOrganisationId);
    archiveClassModalAction();
  };

  useEffect(() => () => setClassToArchiveAction([], currentOrganisationId), []);

  return (
    <HubPageLayout {...pageLayout}>
      <div>
        <Helmet title={hubContent.my_classes_page_title} titleTemplate={hubContent.page_title_template} />
        <DataRefresher
          loading={areProfileClassesLoading}
          refreshData={() => {
            initialiseSearch('profileClasses', false, { active: true, orgId: currentOrganisationId, userId });
          }}
        />
        <TileContainer
          data={{
            isLoading: areProfileClassesLoading,
            ids: classesIds,
            iconSrc: HubIllustrationConstants.CLASS_STUDENTS,
            title: emptyStateTitle,
            subtitle: emptyStateSubtitle,
            showFirstEmptyStateButton,
            onClickFirstButton: openOnboardingWizardAction,
            to: firstButtonLink,
            firstButtonText,
            firstButtonIcon: GLYPHS.ICON_PLUS,
            isFirstButtonPrimary,
            showEmptyStateJoinClassWithCodeButton,
            onClickJoinClassWithCodeButton: onClickJoinClassWithCodeHandler,
            joinClassWithCodeButtonText,
            items: classes,
            getLink: getClassLink,
            Tile: ClassTile,
            hasPlaceholder: !isLtiMode() && roleIsAtLeast(userRoles.TEACHER, userRole),
            setClassToArchive: _setClassToArchive
          }}
          hubContent={hubContent}
          breakpoint={breakpoint}
          history={history}
          userId={userId}
          userRole={userRole}
          currentOrganisationId={currentOrganisationId}
          currentOrganisationLti={currentOrganisationLti}
          customOrgId={organization?.customId}
          openEditClassroom={openEditClassroom}
        />
      </div>
    </HubPageLayout>
  );
}

export default compose(
  connect(
    ({
      identity: { userId, role: userRole, currentOrganisationId, currentOrganisationLti },
      organisations: { data: orgs = {} } = {},
      search: { profileClasses: { currentClassList = {}, loading: areProfileClassesLoading = false } = {} } = {}
    }) => {
      const classesIds = Object.keys(currentClassList);

      return {
        organization: orgs[currentOrganisationId] || {},
        classes: currentClassList,
        classesIds,
        areProfileClassesLoading,
        userId,
        userRole,
        currentOrganisationId,
        currentOrganisationLti
      };
    },
    {
      openCreateClassModalAction: openCreateClassModal,
      openOnboardingWizardAction: openOnboardingWizard,
      onClickJoinClassWithCodeHandler: openJoinClassWithCodeModal,
      setClassToArchiveAction: setClassroomsToArchive,
      archiveClassModalAction: toggleArchiveClassModal,
      initialiseSearch: initialiseInstance,
      openEditClassroom: openEditClassroomModal
    }
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyClasses);

MyClasses.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classesIds: PropTypes.array.isRequired,
  onClickJoinClassWithCodeHandler: PropTypes.func,
  setClassToArchiveAction: PropTypes.func,
  archiveClassModalAction: PropTypes.func,
  openEditClassroom: PropTypes.func,
  hubContent: PropTypes.object.isRequired,
  areProfileClassesLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  currentOrganisationId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  initialiseSearch: PropTypes.func,
  organization: PropTypes.object,
  openOnboardingWizardAction: PropTypes.func,
  pageLayout: PropTypes.shape({
    ...HubPageLayout.propTypes,
    showPageInnerHero: PropTypes.bool
  })
};
