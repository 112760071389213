import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import Button from '@oup/shared-front-end/src/components/Button';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';

import Toggle from '@oup/shared-front-end/src/components/Toggle';
import LockIcon from '@oup/shared-front-end/src/svg/oup/icon-close-24.svg';
import SelectInputs from './SelectInputs/SelectInputs';
import Tooltip from '../../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import {
  setEditedPlacementTest,
  setNotificationReminders,
  setSendEmailToStudents
} from '../../../redux/reducers/placementTestSessionCreate';

import style from './EmailNotificationsAndReminders.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { DEFAULT_REMINDERS, INTERVAL_OPTIONS, MOMENT_OPTIONS } from '../utils/constants';
import withLocalizedContent from '../../../language/withLocalizedContent';

function EmailNotificationsAndReminders({
  placementTestSessionName,
  setEditedPlacementTestAction,
  editedPlacementTest,
  setSendEmailToStudentsAction,
  setNotificationRemindersAction,
  sendEmailsToStudents,
  notificationReminders = [],
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { handleStep, isLoading, setIsPrimaryButtonDisabled } = useWizard();
  const [stepOut, setStepOut] = useState(false);

  setIsPrimaryButtonDisabled(
    isLoading || (sendEmailsToStudents && notificationReminders.find(item => item.timePeriod === 'select'))
  );

  useEffect(() => {
    if (!sendEmailsToStudents && editedPlacementTest?.placementTestType === 'OPT_YL') {
      setSendEmailToStudentsAction(false);
    } else {
      setSendEmailToStudentsAction(true);
    }
    if (!notificationReminders.length && editedPlacementTest?.placementTestType !== 'OPT_YL') {
      setNotificationRemindersAction(DEFAULT_REMINDERS);
    }
  }, []);

  const handleSelectChange = (pairIndex, selectName, value) => {
    const updatedPairs = [...notificationReminders];
    updatedPairs[pairIndex] = { ...updatedPairs[pairIndex], [selectName]: value };
    setNotificationRemindersAction(updatedPairs);
  };

  const handleRemove = index => {
    const updatedState = [...notificationReminders];
    updatedState.splice(index, 1);
    setNotificationRemindersAction(updatedState);
  };

  const addNewInputPair = () => {
    const updatedState = [...notificationReminders, DEFAULT_REMINDERS[0]];
    setNotificationRemindersAction(updatedState);
  };

  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  const handleNotifications = () => {
    const data = {
      sendEmailsToStudents
    };
    if (notificationReminders.find(item => item.timePeriod !== '' && Number.isInteger(+item.timePeriod)))
      data.notificationReminders = notificationReminders.map(item => ({
        reminderType: 'EMAIL',
        timePeriod: +item.timePeriod,
        reminderEvent: item.reminderEvent
      }));
    setEditedPlacementTestAction(data);
  };

  handleStep(async () => {
    setStepOut(true);
    handleNotifications();
    await animationTimeout();
  });

  const onToggle = () => {
    setEditedPlacementTestAction({ sendEmailsToStudents: !sendEmailsToStudents });
    setSendEmailToStudentsAction(!sendEmailsToStudents);
    if (!notificationReminders.length) {
      setNotificationRemindersAction(DEFAULT_REMINDERS);
    }
  };

  const ToggleComponent = useCallback(
    () => <Toggle toggle={onToggle} icon={LockIcon} toggled={sendEmailsToStudents} />,
    [sendEmailsToStudents]
  );

  const ReminderComponent = useCallback(
    () => (
      <div>
        {sendEmailsToStudents ? (
          <>
            <div className={style.selectionContainer}>
              {notificationReminders.map((pair, index) => (
                <SelectInputs
                  label={index !== 0 ? 'and' : 'Set reminders'}
                  intervalValue={pair.timePeriod}
                  momentValue={pair.reminderEvent}
                  intervalOptions={INTERVAL_OPTIONS}
                  momentOptions={MOMENT_OPTIONS}
                  id={`${index}-selector`}
                  key={index}
                  customLabelClass={index !== 0 && style.labelThinWeight}
                  onChange={({ target: { value, name } }) => handleSelectChange(index, name, Number(value))}
                  onRemove={() => handleRemove(index)}
                />
              ))}
              <div className="gin-top3">
                <Button
                  variant="outline"
                  text={placementTestsContent.placement_test_notification_page_button_label}
                  icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
                  onClick={() => addNewInputPair()}
                />
              </div>
            </div>
            <div className="gin-top6">
              <ValidationMessage state="information">
                <div className={style.noteText}>{placementTestsContent.placement_test_notification_page_note_text}</div>
              </ValidationMessage>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    ),
    [sendEmailsToStudents, notificationReminders]
  );

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_EMAIL_NOTIFICATIONS_CONTAINER"
      className={`${fadeOut}`}
    >
      <WizardStep
        titleText={`${placementTestsContent.placement_test_notification_page_title} ${placementTestSessionName ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
      >
        <div className={style.notificationsColumn}>
          <div className={style.customiseToggle}>
            <ToggleComponent />
            <span className={style.notificationText}>
              {placementTestsContent.placement_test_notification_page_toggle_label}
            </span>
            <Tooltip
              className={style.orgStatusLicenceItemTooltip}
              title={placementTestsContent.placement_test_notification_page_tooltip_text}
            >
              <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={style.infoIcon} />
            </Tooltip>
          </div>
          <ReminderComponent />
        </div>
      </WizardStep>
    </div>
  );
}

EmailNotificationsAndReminders.propTypes = {
  setEditedPlacementTestAction: PropTypes.func.isRequired,
  placementTestSessionName: PropTypes.string,
  editedPlacementTest: PropTypes.object,
  setSendEmailToStudentsAction: PropTypes.func,
  setNotificationRemindersAction: PropTypes.func,
  sendEmailsToStudents: PropTypes.bool,
  notificationReminders: PropTypes.array,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      sendEmailsToStudents: placementTestSessionCreate.placementTest.sendEmailsToStudents,
      notificationReminders: placementTestSessionCreate.placementTest.notificationReminders,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      setSendEmailToStudentsAction: setSendEmailToStudents,
      setNotificationRemindersAction: setNotificationReminders
    }
  )
)(EmailNotificationsAndReminders);
