import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { compose } from 'recompose';
import styles from './SecondaryLevelCourses.scss';
// HOC
import withBreakpoint from '../../../decorators/withBreakpoint';
// Services
import getCourseLink from '../Services/getCourseLink';
import getCourseLevel from '../Services/getCourseLevel';
import isCourseReadyToLaunch from '../Utils/isCourseReadyToLaunch';
// Components
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { GLYPHS } from '../../../components/Button/Button';
import Link from '../../../components/Link/Link';
import HubLabel from '../../../components/HubLabel/HubLabel';
// Constants
import breakpoints from '../../../globals/breakpoints';
import { HubLayoutConstants } from '../../../globals/hubConstants';

import getCourseCoverImage from '../Utils/getCourseCoverImage';
import { featureIsEnabled } from '../../../globals/envSettings';

const COURSE_LIMIT = HubLayoutConstants.SUBMENU_ITEMS_LIMIT;

function SecondaryLevelCourses({ courses, courseIds, hubContent, breakpoint, handleSelectSecondaryLevel }) {
  return (
    <li className={styles.secondaryLevelCourses}>
      {courseIds.slice(0, COURSE_LIMIT).map(id => {
        const courseLevel = getCourseLevel(get(courses[id], ['properties']));
        const isCourseReady = isCourseReadyToLaunch(courses[id]);
        return (
          get(courses[id], 'properties') && (
            <Link
              key={id}
              onClick={handleSelectSecondaryLevel}
              to={
                breakpoint === breakpoints.XXS && !featureIsEnabled('navigation-changes')
                  ? getCourseLink(id)
                  : `${getCourseLink(id)}/courseMaterial`
              }
              className={styles.secondaryLevelCourse}
            >
              <div className={styles.imgContainer}>
                {get(courses[id], ['properties', 'coverImage']) ? (
                  <img src={getCourseCoverImage(courses[id])} alt={courses[id].title} />
                ) : (
                  <div>
                    <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
                  </div>
                )}
              </div>
              <div className={styles.textContainer}>
                {!isCourseReady && (
                  <HubLabel
                    text={hubContent.hub_label_coming_soon}
                    customClassName={styles.comingSoonLabel}
                    isCollapsed={false}
                    PrefixIcon={false}
                  />
                )}
                <div className={styles.course}>{courses[id].title}</div>
                {courseLevel && <div className={styles.level}>{courseLevel}</div>}
              </div>
            </Link>
          )
        );
      })}

      {courseIds.length > COURSE_LIMIT ? (
        <Link
          onClick={handleSelectSecondaryLevel}
          className={styles.secondaryLevelCourse}
          to={HubLayoutConstants.PATH_NAMES.COURSES_PATH}
        >
          <div className={styles.secondaryLevelHiddenCourses}>
            <div>+{courseIds.length - COURSE_LIMIT}</div>
          </div>
          <div className={styles.hiddenCoursesText}>{hubContent.more_text}</div>
        </Link>
      ) : null}
    </li>
  );
}

SecondaryLevelCourses.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courseIds: PropTypes.array.isRequired,
  courses: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  handleSelectSecondaryLevel: PropTypes.func
};

export default compose(
  connect(({ hubCourses: { courses = {}, courseIds = [] } = {} }) => ({ courses, courseIds })),
  withBreakpoint
)(SecondaryLevelCourses);
