import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';

import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import styles from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestLanguageVariation/PlacementTestLanguageVariation.scss';
import { getContentPreviewRequest } from '../../../../redux/actions/contentPreview';
import {
  setLanguageVariationCode,
  setListeningAccentsCode,
  setPlacementTestType
} from '../../../../redux/reducers/placementTestSessionCreate';
import { OPT_YL } from '../../../../components/PlacementTestOnBoardingWizard/utils/constants';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditLanguageVariation({
  panelSessionName,
  content,
  setListeningAccentsCodeAction,
  listeningAccentsCode,
  setLanguageVariationCodeAction,
  languageVariationCode,
  setPlacementTestTypeAction,
  placementTestType,
  getContentPreviewRequestAction,
  updatePlacementTestSessionRequestAction,
  placementTest,
  cancelLanguageVariationPanel,
  orgId,
  placementTestSessionId,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [showLanguageVariation, setShowLanguageVariation] = useState(false);
  const [showAccents, setShowAccents] = useState(false);
  const [isLanguageVariationTouched, setIsLanguageVariationTouched] = useState(false);
  const [editedListeningAccentsCode, setEditedListeningAccentsCode] = useState(listeningAccentsCode);
  const [editedLanguageVariationCode, setEditedLanguageVariationCode] = useState(languageVariationCode);

  useEffect(() => {
    getContentPreviewRequestAction({
      source: 'am'
    });
  }, []);

  useEffect(() => {
    if (placementTestType && content && content.length) {
      const selected = placementTestType === OPT_YL ? content[1] : content[0];
      setSelectedProduct(selected);
      setPlacementTestTypeAction(selected.title);
    }
  }, [content]);

  useEffect(() => {
    if (languageVariationCode) setShowLanguageVariation(true);
  }, [languageVariationCode]);

  useEffect(() => {
    if (listeningAccentsCode) setShowAccents(true);
  }, [listeningAccentsCode]);

  const handleLanguageVariationSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === e.target.value.toString()
    );

    setEditedLanguageVariationCode(chosenLanguageVariation.language_variation_code);
    setIsLanguageVariationTouched(false);
  };

  const handleListeningAccentSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === editedLanguageVariationCode
    );
    const choosenListeningAccents = chosenLanguageVariation.listening_accents.find(
      listeningAccent => listeningAccent['assessment_master:package_org_code'] === e.target.value
    );
    setEditedListeningAccentsCode(choosenListeningAccents['assessment_master:package_org_code']);
    setIsLanguageVariationTouched(true);
  };

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_test_settings}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <PopoutActionFooter
          wizardButtons
          withIcon={false}
          primaryButtonText={hubContent.save}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              placementTestSessionId,
              placementTest: {
                ...placementTest,
                editedLanguageVariationCode,
                editedListeningAccentsCode
              }
            });
            setLanguageVariationCodeAction(editedLanguageVariationCode);
            setListeningAccentsCodeAction(editedListeningAccentsCode);
            cancelLanguageVariationPanel(e, true);
          }}
          secondaryButtonText={hubContent.cancel}
          secondaryButtonAction={cancelLanguageVariationPanel}
          primaryButtonDisabled={!isLanguageVariationTouched}
        />
      }
    >
      <div className="pad2">
        <div className={styles.languageVariationsContainer}>
          <div>
            <img src={selectedProduct.cover} alt={selectedProduct.title} />
          </div>
          {showLanguageVariation && selectedProduct && (
            <div className={styles.languageVariationsCheckboxes}>
              <h3>{placementTestsContent.use_of_english}</h3>
              <RadioButtonList
                type="outline"
                name="language-variation-group-2"
                legend="legend name test 2"
                noShadow
                checkedValue={[editedLanguageVariationCode]}
                radioGroup={selectedProduct.language_variations.map((variation, index) => ({
                  id: `language-variation-radio-${index}`,
                  label: placementTestsContent[variation.language_variation_code],
                  value: variation.language_variation_code
                }))}
                onChange={handleLanguageVariationSelection}
              />
            </div>
          )}
        </div>
        {showAccents && languageVariationCode && selectedProduct && (
          <div className={styles.languageVariationsCheckboxes}>
            <h3>{placementTestsContent.listening_accents}</h3>
            <RadioButtonList
              type="outline"
              name="listening-accent-variation-group-3"
              legend="legend name test 3"
              noShadow
              checkedValue={[editedListeningAccentsCode]}
              radioGroup={selectedProduct.language_variations
                .find(
                  languageVariation =>
                    languageVariation.language_variation_code.toString() === editedLanguageVariationCode
                )
                .listening_accents.map((accent, accentIndex) => ({
                  id: `listening-accent-variation-radio-${accentIndex}`,
                  label: placementTestsContent[accent['assessment_master:package_org_code']],
                  value: accent['assessment_master:package_org_code']
                }))}
              onChange={handleListeningAccentSelection}
            />
          </div>
        )}
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditLanguageVariation.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  content: PropTypes.object,
  listeningAccentsCode: PropTypes.string,
  setListeningAccentsCodeAction: PropTypes.func,
  languageVariationCode: PropTypes.string,
  setLanguageVariationCodeAction: PropTypes.func,
  placementTestType: PropTypes.string,
  setPlacementTestTypeAction: PropTypes.func,
  getContentPreviewRequestAction: PropTypes.func,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  placementTest: PropTypes.object,
  cancelLanguageVariationPanel: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, contentPreview, identity }) => ({
      listeningAccentsCode: placementTestSessionCreate.placementTest.listeningAccentsCode,
      languageVariationCode: placementTestSessionCreate.placementTest.languageVariationCode,
      placementTestType: placementTestSessionCreate.placementTest.placementTestType,
      content: contentPreview.data || contentPreview.results,
      placementTest: placementTestSessionCreate.placementTest,
      orgId: identity.currentOrganisationId,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId
    }),
    {
      setListeningAccentsCodeAction: setListeningAccentsCode,
      setLanguageVariationCodeAction: setLanguageVariationCode,
      setPlacementTestTypeAction: setPlacementTestType,
      getContentPreviewRequestAction: getContentPreviewRequest,
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest
    }
  )
)(PlacementTestEditLanguageVariation);
