import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styles from './CourseTile.scss';
import Link from '../Link/Link';
import { HubFallbackImg as FallbackImage } from '../HubImage';
import breakpoints from '../../globals/breakpoints';
import HubLabel from '../HubLabel/HubLabel';
import getCourseLevel from '../../structure/HubDashboardLayout/Services/getCourseLevel';
import isCourseReadyToLaunch from '../../structure/HubDashboardLayout/Utils/isCourseReadyToLaunch';
import getCourseCoverImage from '../../structure/HubDashboardLayout/Utils/getCourseCoverImage';
import { featureIsEnabled } from '../../globals/envSettings';

function CourseTile({ item: course, to, breakpoint, hubContent }) {
  const courseLevel = getCourseLevel(get(course, ['properties']));
  const isCourseReady = isCourseReadyToLaunch(course);
  return (
    <div data-list="tile">
      <Link
        to={
          breakpoint === breakpoints.XXS && !featureIsEnabled('navigation-changes')
            ? to
            : `${to}/${hubContent.course_material_text_endpoint}`
        }
        className={styles.course}
      >
        <div
          className={styles.imgContainer}
          style={
            breakpoint !== breakpoints.XXS
              ? {
                  backgroundColor: get(course, ['properties', 'courseThemeColor'], `{colors.BLUE_COLOR_DARK}`)
                }
              : {}
          }
        >
          {get(course, ['properties', 'coverImage']) ? (
            <img src={getCourseCoverImage(course)} alt={`Course: ${course.title}`} />
          ) : (
            <FallbackImage breakpoint={breakpoint} />
          )}
        </div>
        <div className={styles.textContainer}>
          <div>
            {!isCourseReady && (
              <div className={styles.labelsContainer}>
                <HubLabel
                  text={hubContent.hub_label_coming_soon}
                  customClassName={styles.comingSoonLabel}
                  isCollapsed={false}
                  PrefixIcon={false}
                />
              </div>
            )}
            <span>{course.title}</span>
            {courseLevel && <p>{courseLevel}</p>}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CourseTile;

CourseTile.propTypes = {
  breakpoint: PropTypes.string,
  item: PropTypes.object,
  to: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired
};

CourseTile.defaultProps = {
  breakpoint: '',
  item: {}
};
